<template>
  <iframe style="height: 100vh; width: 100vw" allow="fullscreen" v-show="isLoaded" class="iframe-container" v-bind:src="launchURL" allowfullscreen webkitallowfullscreen></iframe>
</template>
<script>

import prag from "@/services/prag";
import pragmaticplay from "@/services/pragmaticplay";
import stp from "@/services/stp";
import elbet from "@/services/elbet";
import smart from "@/services/smart";
import jetsafi from "@/services/jetsafi";
import spribe from "@/services/spribe";
import booming from "@/services/booming";
import shacksevo from "@/services/shacksevo";


export default {
  name: "CasinoGames",
  components: {
  },
  data: function () {
    return {
      loading: "",
      gameName: "",
      gameID: '',
      icon: "",
      isLoaded: false,
      launchURL: "",
      launchParameters: {},
      launchType: 1,
      isDemo: 0,
    };

  },
  mounted() {

    this.$store.dispatch("setCurrentPage", "casinogames");
    this.reloadProfile();

  },
  computed: {

    app: function () {
      return this.$store.state.app;
    },


    profile: function () {
      return this.getProfile();
    },
    token: function () {
      return this.getAuth();
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        // react to route changes...
        this.gameName = to.params.gameName;
        this.gameID = to.params.gameID;
        this.launchParameters = to.params.launchParameters;
        this.launchType = to.params.launchType;
        this.isDemo = to.params.IsDemo;

        let payload = {
          provider_id: to.params.providerID,
          game_name: to.params.gameName,
          game_id: to.params.gameID,
          is_demo: to.params.isDemo,
        }

        this.getLaunchUrl(payload)

      },
    },
  },
  methods: {

    getLaunchUrl(data) {

      console.log('Launch '+JSON.stringify(data, undefined, 2))

      if (!this.profile) {

        this.setError("Login", "Please login to proceed");
        this.setValue("placeBet", 3);
        this.$router.push({ name: "login", params: {} });
        return;
      }

      var providerId = data.provider_id;
      var gameId = data.game_id;
      var gameName = data.game_name;
      var accountID = parseInt(process.env.VUE_APP_CASINO_ACCOUNT_ID);

      var device_type = this.isMobile() ? "mobile" : "desktop";

      let payload = {};

      payload = {
        account_id: accountID,
        game_id: String(gameId),
        game_name: gameName,
        return_url: window.location.origin+'/casino',
        lobby_url: window.location.origin+'/casino',
        providerId: providerId,
      };

      // Determine which endpoint and payload to use based on provider ID
      if (providerId === 1 || providerId === 11 ) {

        payload = {
          account_id: accountID,
          game_id: String(gameId),
          game_name: gameName,
          return_url: window.location.origin+'/casino',
          lobby_url: window.location.origin+'/casino',
          providerId: providerId,
        };
        
      } else if (providerId === 3) {

        payload = {
          account_id: parseInt(accountID),
          game_id: parseInt(gameId),
          game_name: gameName,
          device_type: String(device_type),
          return_url: window.location.origin+'/casino',
          providerId: providerId,
        };

        
      } else if (providerId === 5) {

        payload = {
          account_id: accountID,
          device_type: String(device_type),
          game_id: String(gameId),
          game_name: gameName,
          return_url: window.location.origin+'/casino',
          providerId: providerId,
        };

        
      } else if (providerId === 4) {
        
        payload = {
          account_id: accountID,
          device_type: String(device_type),
          game_id: String(gameId),
          game_name: gameName,
          return_url: window.location.origin+'/casino',
          providerId: providerId,
        };

        
      } else if (providerId === 4) {

        payload = {
          account_id: accountID,
          device_type: String(device_type),
          game_id: String(gameId),
          game_name: gameName,
          return_url: window.location.origin+'/casino',
          providerId: providerId,
        };

      } else if (providerId === 6) {
        
        payload = {
          account_id: accountID,
          device_type: String(device_type),
          game_id: String(gameId),
          game_name: gameName,
          return_url: window.location.origin+'/casino',
          providerId: providerId,
        };

        
      } else if (providerId === 7) {
        
        payload = {
          account_id: accountID,
          device_type: String(device_type),
          game_id: String(gameId),
          game_name: gameName,
          return_url: window.location.origin+'/casino',
          providerId: providerId,
        };

        
      }
      else if (providerId === 8) {
        
        payload = {
          account_id: accountID,
          demo: 0,
          device: String(`Device ${device_type}`),
          game_id: String(gameId),
          game_name: gameName,
          return_url: window.location.origin+'/casino',
        };
        
      }

      console.log('provider '+providerId+' | payload '+JSON.stringify(payload, undefined, 2))

      // Make API request with appropriate endpoint and payload
      return this.launchGame(payload); // Return the launch URL
      

    },

    launchGame: function(payload) {

      var vm = this;
      var providerId = payload.providerId;

      var service;

      switch (providerId) {
        case 1:
          service = prag;
          break;
        case 3:
          service = stp;
          break;
        case 4:
          service = elbet;
          break;

        case 5:
          service = smart;
          break;
        case 6:
          service = jetsafi;
          break;

        case 7:
          service = spribe;
          break;

        case 8:
          service = booming;
          break;

        case 9:
          service = shacksevo;
          break;

        case 11:
          service = pragmaticplay;
          break;

        default:
          console.error("Invalid providerId:", providerId);
          return; // Exit the function if providerId is invalid
      }

      service
          .post("/launch/url", payload, {
            headers: {
              "api-key": vm.getAuth(),
            }
          })
          .then(response => {

            vm.launchURL = response.data.game_url;
            console.log("LaunchURL", vm.launchURL)

            if (vm.launchType === "3" ) {

              window.open(this.launchURL);

            }

            console.log("LaunchUrl", vm.launchURL)
            this.isLoaded = true; // Set isLoaded to true after getting the launch URL
            return vm.launchURL;

          })
          .catch(error => {
            console.error("Error launching game:", error);
          });
    },

    goToCasino: function () {

      this.$router.push({ name: "casino", params: {} });

    },

    showDeposit: function () {

      document.getElementById("deposit-init").click();

    },

  },
  filters: {

    amount: function (val) {

      if (val === undefined) {
        return val;
      }

      return parseFloat(val).toFixed(2).toLocaleString();
    },

  },
};
</script>

<style scoped>
.title_name {
  color: #fff;
}
.parent {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.parent .banner {
  width: 100%;
  /* height: 48px; */
}
.parent iframe {
  background: #000;
  border: none;
  flex-grow: 1;
}
h4 {
  position: relative;
  top: 0.6em;
  right: 1em;
}
</style>